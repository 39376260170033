* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
